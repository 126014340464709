




























































import Vue from 'vue';
import { Location } from 'vue-router';
import { ApiResource } from '@/types';
import StatusTag from '@/components/media/CommissionStatusTag.vue';

export default Vue.extend({
  components: {
    StatusTag,
  },
  props: {
    commission: {
      required: true,
      type: Object as () => ApiResource.Commission,
      // https://github.com/vuejs/vue/issues/9873#issuecomment-485481541
      // validator(obj: any) {
      //   return obj.object === 'commission';
      // },
    },
    routerLinkTo: {
      required: true,
      type: Object as () => Location,
    },
    showStatusTag: Boolean,
    showFullSummary: Boolean,
  },
  data() {
    return {
      expand: false,
    };
  },
  computed: {
    redirect(): Location {
      const r: Location = this.routerLinkTo;

      if (!r.params) r.params = {};

      r.params.COMMISSION_ID = this.commission.id;

      return r;
    },
    trimmedSummary(): string {
      const LIMIT = 200;

      if (this.commission.summary.length >= LIMIT) {
        return this.commission.summary.substring(0, LIMIT).concat('…');
      }
      return this.commission.summary;
    },
  },
});
