












import Vue, { VueConstructor } from 'vue';

export default (Vue as VueConstructor<Vue>).extend({
  data() {
    return {
      error: null as Error | null,
    };
  },
  mounted() {
    this.fetchCommissions(20, 0, 'updated_at', 'desc');
    this.fetchBusinesses();
  },
  methods: {
    handleError(e: Error) {
      this.error = e;
    },
    async fetchBusinesses() {
      await this.$store.dispatch('account/ListBusinesses')
        .catch(this.handleError);
    },
    async fetchCommissions(limit = 20, offset = 0, order = 'updated_at', direction = 'desc', status: string[] = []) {
      await this.$store.dispatch('account/ListCommissions', {
        query: {
          limit, offset, order, direction, status,
        },
      }).then(({ data }: { data: []}) => {
        if (data.length === limit) {
          this.fetchCommissions(
            limit, limit + offset, order, direction, status,
          );
        }
      }).catch(this.handleError);
    },
  },
  meta() {
    return {
      title: this.$t('title').toString(),
    };
  },
});
