








































































































































































import Vue, { VueConstructor } from 'vue';
import { mapGetters } from 'vuex';
import { ApiResource, State } from '@/types';
import MediaCommission from '@/components/media/Commission.vue';

interface VuexBindings {
  commissions: ApiResource.Commission[],
  stats: State.Account['stats'],
}

export default (Vue as VueConstructor<Vue & VuexBindings>).extend({
  components: {
    MediaCommission,
  },
  computed: {
    ...mapGetters({
      commissions: 'commission/List',
      stats: 'account/Stats',
    }),
    filter(): (status: string) => ApiResource.Commission[] {
      return (status) => this.commissions
        .filter((c: ApiResource.Commission) => c.status === status);
    },
    count(): (status: string) => number {
      return (status) => this.filter(status).length;
    },
  },
});
